import React from 'react';
//import { useSelector } from 'react-redux';
import DefaultLayout from '../layouts/DefaultLayout';
import TemplateColumn from '../layouts/TemplateColumn';
import { PageHeader, PageSection, RelatedContent, DefinitionList } from '../components';
import { jobCodes } from '../util/data/jobCodes';
import { launchChatbotWindow } from '../components/Chatbot/ChatbotLauncher';

// SEO INFORMATION
const pageMeta = {
    title: `Contact Information for Health Care Professionals About KEYTRUDA® (pembrolizumab) | HCP`,
    keywords: `keytruda hcp contact information and support`,
    description: `Learn about AskMerck to get your oncology product questions answered as well as other support programs for healthcare professionals that may help answer questions about KEYTRUDA® (pembrolizumab).`,
     schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/contact-us/","@type":"MedicalWebPage","name":"Contact Us","description":"Please contact us for more information"}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/contact-us/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

const jobCode = jobCodes[0].jobCode;

const relatedContent = [
    {
        section: 'Dosing & Preparation',
        link: {
            url: '/dosing/options/',
            label: 'Dosing'
        }
    },
    {
        section: 'Safety',
        link: {
            url: '/safety/adverse-reactions/',
            label: 'Selected Adverse Reactions'
        }
    },
    {
        section: 'Resources',
        link: {
            label: 'Mechanism of Action',
            url: '/resources/mechanism-of-action/',
    }
    },
]

export const contactUsSections = [
    {
        items: [
            {
                title: 'Ask Merck',
                content: `<p>Merck is committed to helping you get your oncology product questions answered.</p>
                <p>Connect with a Merck Associate now or schedule a call by visiting <a href="https://www.askmerckoncology.com" target="_blank">Ask Merck</a>. Merck Associates are available Monday through Friday, 8 AM to 8 PM ET.</p>`
            },
            {
                title: 'Chat',
                content: `<p>KeyChat is your virtual assistant and is here to help answer questions you may have about KEYTRUDA.</p>
                <p><button class="begin-chat">Begin a chat</button> now to see how KeyChat can help you today.</p>`
            },
            {
                title: 'Patient Access & Reimbursement Support',
                content: `<p>The Merck Access Program can help answer questions about billing and coding, benefit investigations, co-pay assistance for eligible patients, referral to the Patient Assistance Program for eligibility determination (provided through the Merck Patient Assistance Program, Inc.) and Product distribution.</p>
                <p>Call <a href="tel:8552573932">855-257-3932</a> Monday through Friday, 8 AM to 8 PM ET to speak with a Merck Access Program representative or visit <a href="https://www.merckaccessprogram-keytruda.com/" target="_blank">The Merck Access Program</a>.</p>`
            },
            {
                title: 'Patient Support Program',
                content: `<p>The KEY+YOU Patient Support Program is here to help with the challenges patients face with cancer through online activities, e-mails, direct mail, and phone support. Eligible patients and caregivers can get help preparing for treatment, dealing with emotions, and connecting to helpful organizations.</p>
                <p>Support is available in both English and Spanish. Patients can call <a href="tel:8553987832">855-398-7832</a> and press 2 to speak to a representative or visit the <a href="/resources/key-you-patient-support-program/">KEY+YOU Patient Support Program</a> to sign up.</p>`
            },
        ]
    } 
];

const Page = ({ location }) => {
    // TODO: could not find react-redux context value; please ensure the component is wrapped in a <Provider>
    //const scrolledPastInPageSSI = useSelector(state => state.scrolledPastInPageSSI);

    // Link an onClick event to a dom node as close as we can to the target.
    const beginChat = (evt) => {
        // Filter on the click event with the given class name of the element we are watching.
        if (evt.target.classList.contains("begin-chat")) {
            launchChatbotWindow({ scrolledPastInPageSSI: false });
        }
    };

    return (
        <DefaultLayout jobCode={jobCode} location={location} pageMeta={ pageMeta }>
            <div data-template-name="template-c">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-c-intro">
                    <PageHeader title="Contact Us" />
                    <PageSection bgColor="cloud" title="Please contact us for more information">
                        <span onClick={beginChat}>
                            <DefinitionList sections={contactUsSections}/>
                        </span>
                    </PageSection>
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}
            </div>
        </DefaultLayout>
    );
};

export default Page;
